import { render, staticRenderFns } from "./slick-units.vue?vue&type=template&id=47f44ae6&scoped=true"
import script from "./slick-units.vue?vue&type=script&lang=js"
export * from "./slick-units.vue?vue&type=script&lang=js"
import style0 from "./slick-units.vue?vue&type=style&index=0&id=47f44ae6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f44ae6",
  null
  
)

export default component.exports