<template>
  <section class="project--detail-panorama" v-if="$mq === 'xs' && validUrl(panoEmbedUrl)">
    <div class="title--text">
      {{ $t('general.panorama') }}
    </div>
    <iframe :src="panoEmbedUrl" allowfullscreen="allowfullscreen" loading="lazy"></iframe>
  </section>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
export default {
  name: 'project-detail-panorama',
  mixins: [HelperMixin],
  props: ['panoEmbedUrl'],
};
</script>
